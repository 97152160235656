export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        user_id: {type: "integer",},
        host: {type: "string"},
        layout: {$ref: "defs#/definitions/layout_group"},
        setting: {type: "object", nullable: true, default: {}},
    },
    required: ["id", "host", "layout"],
}