import Model from "@/models/Model";
import store from '@/service/store'

let userHeartbeat = null;
const timeout = 60 * 1000

const send = () => {
    if (store.state.Token) {
        const model = new Model('auth')
        model.errorNotify(false)
        model.call('heartbeat').catch(() => {
            stopHeartbeat()
        })
    } else {
        stopHeartbeat()
    }
}

export const hasHeartbeat = () => {
    return !!userHeartbeat
}

export const stopHeartbeat = () => {
    clearInterval(userHeartbeat)
    userHeartbeat = null
}

export const restartHeartbeat = (focus = false) => {
    if (!hasHeartbeat() || focus) {
        startHeartbeat()
    }
}

export const startHeartbeat = () => {
    send()
    userHeartbeat = setInterval(() => {
        send()
    }, timeout)
}
