import http, {source} from '../service/http'
import qs from 'qs'
import Vue from "vue";
import ajv from "@/models/schemas/Ajv";
import BusEvent from "@/logics/BusEvent";

class Collection {
    api = null
    primaryKey = 'id'
    models = null
    collection = {}

    constructor(api, primaryKey = 'id') {
        this.api = api
        this.primaryKey = primaryKey
    }

    find(id) {
        if (this.collection[id]) {
            return this.collection[id]
        }
    }

    findBy(condition = {}) {
        return _.filter(this.collection, condition);
    }

    all() {
        return this.collection
    }

    update(id, data) {
        this.collection[id] = data
        return this
    }

    updateList(list) {
        _.keyBy(list, this.primaryKey)
        this.collection = Object.assign(this.collection, list)
        return this
    }

    remove(id) {
        if (this.collection[id]) {
            delete this.collection[id]
        }
    }
}

class Model {
    api = null
    config = {}
    emit = true
    emitIgnore = {}
    emitOnly = {}
    primaryKey = 'id'

    dataType = null
    id = null
    data = null

    queries = {};
    filters = []
    withs = []

    response = {}
    responseStatus = true

    schema = null

    collection = null


    constructor(api, config = {}) {
        this.api = api
        this.config = config
        this.collection = new Collection(api, this.primaryKey)
        let schemas = null
        try {
            schemas = require('./schemas/' + this.api).default
        } catch (e) {
            schemas = null
        }
        this.schema = schemas
        return new Proxy(this, {
            get(target, property) {
                if (property in target) {
                    return target[property];
                } else {
                    return target.data ? target.data[property] : undefined;
                }
            },
            apply(target, thisArg, argumentsList) {
                return target.__call(thisArg, argumentsList)
            }
        })
    }

    select(fields) {
        this.queries.select = fields
        return this
    }

    whereRaw(name, raw) {
        if (!this.queries.where) {
            this.queries.where = {}
        }
        this.queries.where[name] = raw
        return this
    }

    where(name, operator, value) {
        let param = ''
        if (!value) {
            param = operator
        } else {
            param = [operator, value]
        }
        this.whereRaw(name, param)
        return this
    }

    whereNull(name) {
        return this.whereRaw(name, ['Null'])
    }

    whereIn(name, value) {
        return this.where(name, 'In', value)
    }

    with(name) {
        if (!this.queries.with) {
            this.queries.with = []
        }
        if (name instanceof Array) {
            this.queries.with = this.queries.with.concat(name)
        } else if (typeof name === 'string') {
            this.queries.with.push(name)
        }
        return this
    }

    withCount(name) {
        if (!this.queries.withCount) {
            this.queries.withCount = []
        }
        this.queries.withCount.push(name)
        return this
    }

    order(field, sort = 'asc') {
        this.queries.order = field
        this.queries.sort = sort
        return this
    }

    group(field) {
        this.queries.group = field
        return this
    }

    limit(limit) {
        this.queries.limit = limit
        return this
    }

    count(field = 'id') {
        this.queries.count = field
        const api = this.buildQuery(this.api + '/count')
        return this.http('get', `${api}`)
    }

    sum(field) {
        this.queries.sum = field
        return this
    }

    render(item) {
        if (this.schema) {
            const validate = ajv.compile(this.schema)
            const valid = validate(item)
            if (!valid) {
                //todo 通知后台处理
                if (this.schemaExceptionHandler) {
                    return this.schemaExceptionHandler(validate.errors, item)
                }
                // console.log(item)
                // console.log(validate.errors)
                return undefined
            }
        }
        return item
    }

    query(key, value = '', append = false) {
        if (append) {
            if (!this.queries[key]) {
                this.queries[key] = []
            }
            this.queries[key].push(value)
        } else {
            this.queries[key] = value
        }
        return this
    }

    buildQuery(base) {
        return base + '?' + qs.stringify(this.queries)
    }

    find(id) {
        if (!id) {
            throw Error("没有找到" + this.primaryKey)
        }
        const api = this.buildQuery(this.api + '/' + id)
        this.id = id
        return this.responseObject(this.http('get', `${api}`))
    }

    first() {
        const api = this.buildQuery(this.api + '/one')
        return this.responseObject(this.http('get', `${api}`))
    }

    responseObject(http) {
        return new Promise((resolve, reject) => {
            http.then(res => {
                let data = res.data
                data = this.render(data)
                if (!data) {
                    reject(res)
                }
                if (data && data[this.primaryKey]) {
                    this.collection.update(data[this.primaryKey], data)
                }
                this.dataType = 'object'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    get(select = []) {
        if (select.length > 0) {
            this.queries.select = select
        }
        const api = this.buildQuery(this.api)
        return new Promise((resolve, reject) => {
            this.http('get', `${api}`).then(res => {
                let data = res.data
                data = data.map(item => {
                    return this.render(item)
                }).filter(item => item)
                this.collection.updateList(data)
                this.dataType = 'list'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    fetch(select = []) {
        if (select.length > 0) {
            this.queries.select = select
        }
        return new Promise((resolve, reject) => {
            this.http('post', `${this.api}/index`, this.queries).then(res => {
                let data = res.data
                data = data.map(item => {
                    return this.render(item)
                }).filter(item => item)
                this.collection.updateList(data)
                this.dataType = 'list'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    page(page = 1, size = 15) {
        this.queries = {
            ...this.queries,
            paging: true,
            page, size
        }
        const api = this.buildQuery(this.api)
        return new Promise((resolve, reject) => {
            this.http('get', `${api}`).then(res => {
                let data = res.data
                data.data = data.data.map(item => {
                    return this.render(item)
                }).filter(item => item)
                this.collection.updateList(data.data)
                this.dataType = 'paging'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        const api = this.buildQuery(this.api)
        return new Promise((resolve, reject) => {
            this.http('post', api, data).then(res => {
                let data = res.data
                data = this.render(data)
                if (!data) {
                    reject(res)
                }
                if (data && data[this.primaryKey]) {
                    this.collection.update(data[this.primaryKey], data)
                }
                this.dataType = 'object'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(data, id = null) {
        if (!id) {
            if (!this.id) {
                throw new Error('没有更新数据');
            } else {
                id = this.id
            }
        }
        const api = this.buildQuery(`${this.api}/${id}`)
        return new Promise((resolve, reject) => {
            this.http('put', api, data).then(res => {
                let data = res.data
                data = this.render(data)
                if (!data) {
                    reject(res)
                }
                if (data && data[this.primaryKey]) {
                    this.collection.update(data[this.primaryKey], data)
                }
                this.dataType = 'object'
                this.data = data
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id = null) {
        if (!id) {
            if (!this.id) {
                throw new Error('没有删除数据');
            } else {
                id = this.id
            }
        }
        const api = this.buildQuery(`${this.api}/${id}`)
        return new Promise((resolve, reject) => {
            this.http('delete', api).then(res => {
                const data = res.data
                if (data && data[this.primaryKey]) {
                    this.collection.remove(data[this.primaryKey])
                }
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }


    tree() {
        return this.call('tree')
    }


    import(data) {
        return this.call('import', 'post', data)
    }

    destroy(ids) {
        return this.call('destroy', 'post', {ids})
    }

    clear() {
        return this.call('clear')
    }

    call(action, method = 'get', data = null) {
        const api = this.buildQuery(`${this.api}/${action}`)
        return new Promise((resolve, reject) => {
            let promise = null
            if (data) {
                promise = this.http(method, api, data)
            } else {
                promise = this.http(method, api)
            }
            promise.then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    errorNotify(value = true) {
        this.emit = value
        return this
    }

    errorIgnore(value = {}) {
        this.emitIgnore = value
        return this
    }

    errorOnly(value = {}) {
        this.emitOnly = value
        return this
    }

    check(data, match) {
        for (let key in match) {
            if (data[key] !== match[key]) {
                return false
            }
        }
        return true
    }

    http(method, url, data = {}) {
        this.source = source()
        return new Promise((resolve, reject) => {
            let config = {
                method: method,
                url: url,
                cancelToken: this.source.token
            }
            if (['post', 'put'].indexOf(method) >= 0) {
                config.data = data
            }
            config = {
                ...config,
                ...this.config
            }
            http(config).then((response) => {
                this.response = response
                if (response.status === 200) {
                    this.responseStatus = true
                    resolve(response)
                } else {
                    this.responseStatus = false
                    resolve(response)
                }
            }).catch(res => {
                const schemas = require('./schemas/response').default
                const validate = ajv.compile(schemas)
                const valid = validate(res)
                if (valid) {
                    if (this.emit) {
                        if (Object.keys(this.emitOnly).length > 0) {
                            if (this.check(res.data, this.emitOnly)) {
                                Vue.bus.emit('HTTP', new BusEvent(res))
                            }
                        } else if (Object.keys(this.emitIgnore).length > 0) {
                            if (!(this.check(res.data, this.emitIgnore))) {
                                Vue.bus.emit('HTTP', new BusEvent(res))
                            }
                        } else {
                            Vue.bus.emit('HTTP', new BusEvent(res))
                        }
                    }
                }
                reject(res)
            });
        })
    }

    cancel(message = '请求取消') {
        if (this.source) {
            this.source.cancel(message)
        }
    }
}

export default Model
