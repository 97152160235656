export default {
    type: "object",
    properties: {
        id: {type: "integer",},
        user_id: {type: "integer"},
        name: {type: "string", nullable: true},
        is_auto: {type: "boolean"},
        data: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    uuid: {type: "string", format: 'uuid'},
                    data: {$ref: "defs#/definitions/layout_group"},
                },
            },
        },
    },
    required: ["id", "user_id", "name", 'data'],
}