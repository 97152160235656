import Ajv from "ajv"
import defsSchema from './index'

const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}
ajv.addFormat('date-time', (data) => {
    const datetimeFormat = /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
    return datetimeFormat.test(data) //格式必须为haha
})

ajv.addSchema(defsSchema)
export default ajv