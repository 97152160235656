const IS_PROD = ["production", "prod"].includes(process.env.NODE_ENV);
// const localApi = "http://workstation.local.com/api";
// const devApi= "http://dev.gogoing.work/api";
export default {
    baseURL: IS_PROD ? "https://service.gogoing.work/api" : "https://workstation.local.com/api",
    timeout: 30000,
    headers: {
        // "X-Requested-With": 'XMLHttpRequest' //ajax请求标识
        // 'app_id': 'bc1e9e21-2e55-4924-b5b8-2fb2108e53e0'
    }
}
