export default {
    type: "object",
    properties: {
        id: {type: "integer",},
        name: {type: "string",},
        label: {type: "string", nullable: true},
        component_string: {type: "string", nullable: true},
        form: {type: "array",},
        default_setting: {type: "object",},
        status: {type: "string", nullable: true},
        private: {
            anyOf: [
                {
                    type: "boolean"
                },
                {
                    type: "integer"
                }
            ]
        }
    },
    required: ["id", "name", "label", "component_string", "form", "default_setting"],
}