export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        label: {type: "string",},
        regex: {type: "string"},
        component: {type: "string"},
        position: {type: "string", nullable: true, default: "Right", enum: ["Right", "Dropdown"]},
        used_count: {type: "integer", nullable: true},
        listen: {type: "boolean", nullable: true, default: 0},
    },
    required: ["id", "label", "component", 'regex'],
}