export default {
    type: "object",
    properties: {
        fixed: {$ref: 'defs#/definitions/layout_item'},
        recommend: {$ref: 'defs#/definitions/layout_item'},
        groups: {
            type: "array",
            items:{
                type:"object",
                properties:{
                    name: {type: "string"},
                    layouts: {$ref: 'defs#/definitions/layout_item'}
                },
                required: ["name"]
            },
        }
    },
}