export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        name: {type: "string",},
        icon: {type: "string",nullable: true,},
        layout_id: {type: "integer", nullable: true},
    },
    required: ["id", "name"],
}