<template>
  <i :class='`remixicon ri-${name}`+(s?`-${s}`:``)'></i>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {},
    s: {
      default() {
        return 'line'
      }
    }
  },
}
</script>

<style scoped>
.remixicon {
  vertical-align: sub;
}
</style>
