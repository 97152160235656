export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        from_user_id: {type: "integer", nullable: true,},
        from_user: {$ref: 'defs#/definitions/user', nullable: true,},
        to_user_id: {type: "integer", nullable: true,},
        to_user: {$ref: 'defs#/definitions/user', nullable: true,},
        is_system: {type: "boolean",},
        read_at: {type: "string", format: "date-time", nullable: true,},
        received_at: {type: "string", format: "date-time", nullable: true,},

        title: {type: 'string',},
        content: {type: 'string',},
        attachments: {
            type: 'array',
        },
        actions: {
            type: 'array',
        },
    },
    required: ["id", "to_user_id", 'title', 'content'],
}