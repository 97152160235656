export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        account: {type: 'string'},
        avatar: {type: 'string'},
        username: {type: 'string'},
        plugins: {type: "array",},
        unique_code: {type: "string"},
        role: {type: "string"},
        auto_login: {type: "boolean"},
        default_layout_id: {type: "integer"},
        uuid: {type: "string", format: "uuid"},
        open_id: {type: "string"},
        last_online_at: {type: "string", format: 'date-time', nullable: true},
        synced_at: {type: "string", format: 'date-time', nullable: true},
    },
    required: ["id", "uuid", 'username', 'plugins', 'unique_code', 'role'],
}