import Vue from 'vue'
import VueIconfont from 'vue-iconfont'

import '@/assets/fonts/extension'

Vue.use(VueIconfont, [
    // 定义 v-icon 组件以使用 font-class 图标
    // {
    //     tag: 'v-icon',
    //     prefix: 'v-icon',
    //     type: 'font'
    // },

    // 定义 v-svg-icon 组件以使用 SVG 图标
    {
        tag: 'v-svg-icon',
        prefix: 'v-icon',
        type: 'svg'
    }
])