export default {
    type: "object",
    default: {},
    properties: {
        config: {type: 'object'},
        data: {
            type: "object",
            properties: {
                error_code: {type: 'string', nullable: true},
                message: {type: 'string', nullable: true},
                status: {type: 'integer'},
                type: {type: 'string', nullable: true},
            },
        },
        headers: {type: 'object'},
        status: {type: 'integer'},
        statusText: {type: 'string', nullable: true}
    },
    required: ["config", "data", 'headers', 'status', 'statusText'],
}