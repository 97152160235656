import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import config from '../config/http'
import md5 from 'spark-md5'
import store from './store'


const CancelToken = axios.CancelToken;
const source = CancelToken.source;
export {source}

// axios 配置
axios.defaults = Object.assign(axios.defaults, config)

// http request 拦截器
axios.interceptors.request.use(
    config => {
        // 提交前处理
        const time = (new Date()).getTime().toString().substring(0, 10)
        const app = require('../config/app').default  //不知道为什么用import,eslint会报错
        config.headers['timestamp'] = time
        config.headers['version'] = app.version
        config.headers['sign'] = md5.hash(app.securityKey + time)
        const token = Vue.ls.get('Token')
        if (token) { //判断token是否存在
            config.headers.Authorization = 'Bearer ' + token;  //将token设置成请求头
        }
        const uniqueCode = Vue.ls.get('UniqueCode')
        if (uniqueCode) { //判断uniqueCode是否存在
            config.headers['unique-code'] = uniqueCode;
        }
        const uuid = Vue.ls.get('Uuid')
        if (uuid) { //判断uuid是否存在
            config.headers['uuid'] = uuid;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    (response) => {
        // 获取数据后理

        if (response.headers && response.headers.authorization) {
            const token = response.headers.authorization.replace('Bearer ', '')
            store.commit('setStorage', {Token: token})
            // console.log('RefreshToken:' + token)
        }
        if (response.status === 200) {
            return response.data
        }
        return response;
    },
    error => {
        //未知错误
        const unknownError = {
            'status': 600,
            'error_code': 'ClientError',
            'type': 'boolean',
            'message': '未知错误',
            'data': false,
        }
        if (error.response) {
            if (error.response.status) {
                if (error.response.status === 401) {
                    store.commit('setStorage', {Token: ''})//清除token
                }
                return Promise.reject(error.response);
            } else {
                return Promise.reject(unknownError);
            }
        } else if (axios.isCancel(error)) {
            unknownError.error_code = 'HttpCancel'
            unknownError.message = '请求取消'
        }
        return Promise.reject(unknownError);
    }
);
Vue.use(VueAxios, axios);
export default axios;