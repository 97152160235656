import {debounce} from "@/tools/helper";
import Model from "@/models/Model";
import store from "@/service/store";
import {message} from "ant-design-vue";
import {restartHeartbeat} from "@/logics/heartbeat";

var typeModel = {}
export const sync = (call, data) => {
    return new Promise((resolve) => {
        const action = 'sync' + call
        if (typeof typeModel[action] === 'undefined') {
            typeModel[action] = new Model('auth', {})
        }
        const model = typeModel[action]
        model.errorIgnore({status: 401})
        model.cancel()
        model.call(action, 'put', data).then((res) => {
            // 同步本地数据到线上
            store.commit('setUser', res.data)
            if (store.state.Setting.syncNotify !== false) {
                message.success({
                    key: 'Synchronous',
                    content: '同步完成',
                });
            }
            //同步事件后检测是否有心跳
            restartHeartbeat()
            resolve()
        }).catch(() => {
            resolve()
        })
    })
}

export const syncAll = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        sync('', {
            layouts: store.state.Layouts,
            setting: store.state.Setting,
            plugins: store.state.Plugins,
            current: store.state.LayoutUuid,
        })
    }, 1000)
}

export const syncLayout = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        if (store.state.Setting.sync === false) {
            return
        }
        if (!store.state.Token) {
            // console.log('游客状态,不更新')
            return null
        }
        const formatUserLayout = store.state.User.default_layout ? store.state.User.default_layout.data : {}
        const formatStorageLayout = {recommend: [], fixed: [], groups: [], ...store.state.Layout.data}
        if (JSON.stringify(formatUserLayout) !== JSON.stringify(formatStorageLayout)) {
            //console.log('同步本地数据到线上', store.state.Layout.data)
            sync('/layout', {layout: store.state.Layout.data, current: store.state.LayoutUuid})
        }
    }, 1000)
}

export const syncSetting = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        if (!store.state.Token) {
            // console.log('游客状态,不更新')
            return null
        }
        const userData = store.state.User.setting || {}
        const storageData = store.state.Setting
        if (JSON.stringify(userData) !== JSON.stringify(storageData)) {
            //console.log('同步本地数据到线上', storageData)
            sync('/setting', {setting: storageData})
        }
    }, 1000)
}

export const syncPlugin = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        if (!store.state.Token) {
            // console.log('游客状态,不更新')
            return null
        }
        const userData = store.state.User.plugins || []
        const storageData = store.state.Plugins
        if (JSON.stringify(userData) !== JSON.stringify(storageData)) {
            //console.log('同步本地数据到线上', storageData)
            sync('/plugin', {plugins: storageData})
        }
    }, 1000)
}

export const syncScene = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        if (!store.state.Token) {
            // console.log('游客状态,不更新')
            return null
        }
        if (store.state.Setting.sync === false) {
            return
        }
        const userData = store.state.User.layouts || []
        const storageData = store.state.Layouts
        //console.log('同步本地数据到线上')
        //console.log(JSON.stringify(userData))
        //console.log(JSON.stringify(storageData))
        if (JSON.stringify(userData) !== JSON.stringify(storageData)) {
            //console.log('同步本地数据到线上', storageData)
            sync('/scene', {layouts: storageData, current: store.state.LayoutUuid})
        }
    }, 1000)
}

export const syncCurrentScene = () => {
    store.commit('refreshSyncedAt')
    debounce(() => {
        if (!store.state.Token) {
            // console.log('游客状态,不更新')
            return null
        }
        if (store.state.Setting.sync === false) {
            return
        }
        if (store.state.User.default_layout_id !== store.state.Layout.id) {
            sync('/scene/current', {current: store.state.LayoutUuid})
        }
    }, 1000)
}