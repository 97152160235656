import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "../router/routes";
import config from '../config/router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: config.mode,
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {

})

export default router
