const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
    }, {
        path: '/:host',
        name: 'Home2',
        component: () => import('../views/Home2')
    },
]
export default routes
