import Vue from 'vue'
import {buildScene} from "@/tools/scene";
import moment from "moment";

export default {
    state: {
        Token: '',
        UniqueCode: '',
        Uuid: '',
        SyncedAt: null,

        Setting: {},

        Layouts: [],
        LayoutUuid: '',
        Layout: {},
        LayoutRefreshing: false,//是否正在刷新布局
        LayoutKey: '',//当前布局的key


        User: {},
        IsNewUser: false,

        Plugins: null,
        IsMobile: false,
    },
    mutations: {
        set(state, data) {
            for (const key in data) {
                state[key] = data[key]
            }
        },
        setStorage(state, data) {
            for (const key in data) {
                // console.log('setStorage', key, data[key])
                state[key] = data[key]
                Vue.ls.set(key, data[key])
            }
        },

        loadLocalSetting() {
            const setting = Vue.ls.get('Setting') || {}
            this.commit('loadSetting', setting)
        },
        loadSetting(state, setting) {
            const defaultSetting = {
                "location": {
                    "ip": null,
                    "country": null,
                    "province": null,
                    "city": null,
                    "county": null,
                    "coordinate": null
                },
                "lang": "zh-CN",
                "theme": {
                    "name": "default",
                    "focus": "blue",
                    "color": null,
                    "backgroundImage": null,
                    "fontSize": "14px",
                    "fontFamily": "Microsoft YaHei",
                },
                "leftSidebar": {
                    "width": "170",
                    "background": null,
                },
                "rightSidebar": {
                    "show": true,
                    "width": "250",
                    "background": "",
                },
                "logo": null,
                "motto": "让工作清爽起来",
                "sidebar": true,
                "sync": true,
                "syncNotify": true,
            }
            for (const key in defaultSetting) {
                const item = defaultSetting[key]
                if (item instanceof Object) {
                    for (const key2 in item) {
                        if (_.has(setting, key + '.' + key2)) {
                            defaultSetting[key][key2] = setting[key][key2]
                        }
                    }
                } else {
                    if (_.has(setting, key)) {
                        defaultSetting[key] = setting[key]
                    }
                }
            }
            this.commit('setStorage', {Setting: defaultSetting})
        },

        loadLocalPlugin() {
            const plugins = Vue.ls.get('Plugins') || null
            console.log(Vue.ls.get('Plugins'))
            this.commit('loadPlugin', plugins)
        },
        loadPlugin(state, plugins = null) {
            if (!plugins) {
                plugins = [
                    {
                        "label": "百度搜索",
                        "regex": "*",
                        "enable": true,
                        "listen": false,
                        "position": "Right",
                        "component": "baidu"
                    },
                    {
                        "label": "创建书签",
                        "regex": "^(http(s)?:\\/\\/)\\w+[^\\s]+(\\.[^\\s]+){1,}$",
                        "enable": true,
                        "listen": false,
                        "position": "Right",
                        "component": "create"
                    },
                    {
                        "label": "书签搜索",
                        "regex": "*",
                        "enable": true,
                        "listen": true,
                        "position": "Dropdown",
                        "component": "search"
                    }
                ]
            }
            this.commit('setStorage', {Plugins: plugins})
        },

        loadLocalLayout() {
            //加载本地布局
            let layouts = Vue.ls.get('Layouts')
            if (!layouts) {
                //初始化布局
                layouts = [buildScene('default')]
            }
            //加载当前布局uuid
            let layoutUuid = Vue.ls.get('LayoutUuid')
            if (!layoutUuid) {
                //没有当前布局，则设置为第一个布局
                layoutUuid = layouts[0].uuid
            }
            this.commit('loadLayouts', {layouts, current: layoutUuid})
        },
        loadDefaultLayout() {
            //加载默认布局
            const layouts = [buildScene('default')]
            this.commit('loadLayouts', {layouts, current: layouts[0].uuid})
        },
        loadLayouts(state, {layouts, current = null}) {
            this.commit('setStorage', {Layouts: layouts})
            if (current) {
                this.commit('refreshLayoutCurrent', current)
            }
        },
        refreshLayout(state, layout) {
            this.commit('setStorage', {Layout: layout})
            this.commit('refreshLayouts')
        },
        refreshLayoutData(state, data) {
            const layout = state.Layout
            layout.data = data
            layout.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
            this.commit('setStorage', {Layout: layout})
            this.commit('refreshLayouts')
            this.commit('refreshSyncedAt')
        },
        refreshLayouts(state) {
            let layouts = state.Layouts.map(item => {
                if (item.uuid === state.Layout.uuid) {
                    return state.Layout
                }
                return item
            })
            this.commit('setStorage', {Layouts: layouts})
        },
        refreshLayoutCurrent(state, current) {
            const layout = state.Layouts.find(item => item.id === current || item.uuid === current)
            if (layout) {
                this.commit('setStorage', {
                    Layout: layout,
                    LayoutUuid: layout.uuid,
                    LayoutKey: '_' + Math.random()
                })
            }
        },

        refreshSyncedAt(state, time = null) {
            if (!time) {
                time = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            this.commit('setStorage', {SyncedAt: time})
            // console.trace('refreshLastUpdatedAt', time)
        },

        loadLocalUser() {
            let user = Vue.ls.get('User') || {}
            user.synced_at = null
            this.commit('setUser', user)
        },

        setUser(state, user) {
            const defaultUser = {
                account: '',
                avatar: '',
                username: '',
                setting: {},
                plugins: null,
                unique_code: '',
                role: '',
                auto_login: '',
                default_layout_id: '',
                layouts: [],
                uuid: '',
                open_id: null,
            }
            const mergedUser = {...defaultUser, ...user}

            if (user.uuid) {
                this.commit('setStorage', {Uuid: user.uuid})
            }
            if (user.token) {
                this.commit('setStorage', {Token: user.token})
            }
            //加载用户云端配置
            if (mergedUser.setting) {
                this.commit('loadSetting', {...mergedUser.setting})
            }
            //加载插件
            if (mergedUser.plugins) {
                this.commit('loadPlugin', [...mergedUser.plugins])
            }
            //加载用户云端场景
            if (mergedUser.layouts instanceof Array && mergedUser.layouts.length > 0) {
                //判断当前场景uuid是否存在,不存在则使用默认场景
                const stateU = mergedUser.layouts.find(item => item.uuid === state.LayoutUuid) || {}
                const def = mergedUser.layouts.find(item => item.id === user.default_layout_id) || {}
                const first = mergedUser.layouts[0] || {}//保底使用第一个场景
                this.commit('loadLayouts', {
                    layouts: [...mergedUser.layouts],
                    current: stateU.id || def.id || first.id
                })
            }
            if (user.synced_at) {
                this.commit('refreshSyncedAt', user.synced_at)
            }
            delete (mergedUser.layouts)
            this.commit('setStorage', {User: mergedUser})
        },
    },
    actions: {},
    modules: {}
}
