export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        unique: {type: "string"},
        name: {type: "string"},
        user_id: {type: "integer", nullable: true,},
        category_id: {type: "integer", nullable: true},
        bookmarks: {
            type: "array",
            items: {$ref: 'defs#/definitions/bookmark'}
        }
    },
    required: ["id", "unique", 'name'],
}