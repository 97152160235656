const definitions = {}
const definitionsFiles = require.context('./', true, /\.js$/)
definitionsFiles.keys().forEach(async key => {
    const name = key.replace(/\.\/(.*?)\.js/, "$1");
    if (name !== "index") {
        const schema = require('./' + name).default
        if (schema) {
            definitions[name] = {$id: name, ...schema}
        }
    }
})
export default {
    $id: "defs", definitions
}