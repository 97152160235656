export default {
    type: "object",
    default: {},
    properties: {
        id: {type: "integer",},
        unique: {type: "string",},
        title: {type: "string"},
        url: {type: "string"},
        icon_path: {type: "string", nullable: true},
        desc: {type: "string", nullable: true},
        liked_count: {type: "integer", nullable: true, default: 0},
        is_official: {type: "boolean", nullable: true, default: false},
        user_id: {type: "integer", nullable: true},
        is_recommend: {type: "boolean", nullable: true, default: false},
        view_count: {type: "integer", nullable: true, default: 0},
        keywords: {type: "string", nullable: true},
    },
    required: ["id", "unique", 'title', 'url'],
}