export default {
    type: "array",
    items: {
        type: "object",
        required: [
            "h",
            "w",
            "id",
            "widget",
        ],
        properties: {
            h: {type: "integer", default: 200},
            w: {type: "integer", default: 200},
            id: {type: "string",},
            data: {type: "object", nullable: true, default: {}},
            widget: {type: "string",},
            resizeable: {type: "boolean", nullable: true, default: false},
        },
    },
}