import {notification} from "ant-design-vue";
import Vue from "vue";


const Notification = (body) => {
    if (body.message) {
        notification.error({
            key: body.error_code,
            message: body.message,
        });
    }
}

const ReloginNotification = (body) => {
    if (body.message) {
        notification.error({
            key: body.error_code,
            message: body.message,
            duration: null,
            btn: h => {
                return h(
                    'a-button',
                    {
                        props: {
                            type: 'primary',
                            size: 'small',
                        },
                        on: {
                            click: () => {
                                Vue.bus.emit('ShowLoginModal')
                                notification.close(body.error_code)
                            },
                        },
                    },
                    '登录',
                );
            },
        });
    }
}

const errorCodes = {
    //Middleware
    HEADER_DATA_MISSING: Notification,
    DECRYPT_METHOD_ERROR: Notification,
    CLIENT_TIME_ERROR: Notification,
    RSA_SIGN_ERROR: Notification,
    MD5_SIGN_ERROR: Notification,

    TOKEN_ERROR: Notification,
    LOGIN_TIMEOUT: Notification,
    TOKEN_VERIFY_FAILED: Notification,
    TOKEN_EXCEPTION: Notification,
    TOKEN_EMPTY: Notification,
    NEED_RELOGIN: ReloginNotification,

    //Auth
    PASSWORD_ERROR: Notification,
    OLD_PASSWORD_ERROR: Notification,
    VISITOR_FORBIDDEN: ReloginNotification,
    FORBIDDEN_ACCESS: Notification,
    SCENARIO_NOT_EXIST: Notification,
    DOMAIN_NAME_USED: Notification,

    //Layout
    LAYOUT_KEEP_DEFAULT: Notification,

    //Share
    FORBID_DELETE_OTHER_DATA: Notification,
    SHARE_NOT_EXIST: Notification,
    SHARE_EXPIRED: Notification,
    FILE_DATA_NOT_EXIST: Notification,
    FILE_NOT_EXIST: Notification,

    //Bookmark
    FAILED_PARSE_URL: Notification,
    URL_FORMAT_ERROR: Notification,
    FORBIDDEN_PARSE_INNER_URL: Notification,
}

export default (busEvent) => {
    const response = busEvent.payload
    const body = response.data
    if (body && typeof errorCodes[body.error_code] !== "undefined") {
        return errorCodes[body.error_code](body)
    }
}