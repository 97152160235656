import {v4 as uuidv4} from "uuid";
import moment from "moment";

const defaultLayout = {
    "fixed": [{
        "h": 110,
        "w": 230,
        "id": "ce561e610119b55c9b6735b94cba451d",
        "data": {"alarms": [], "localUnique": "ce561e610119b55c9b6735b94cba451d"},
        "widget": "clock",
        "resizeable": true
    }],
    "groups": [{
        "name": "常用书签",
        "layout": [{
            "h": 104,
            "w": 210,
            "id": "a186e3048d9ab689b671d375086d3d74",
            "data": {
                "url": "https://www.baidu.com",
                "desc": "全球最大的中文搜索引擎、致力于让网民更便捷地获取信息，找到所求。百度超过千亿的中文网页数据库，可以瞬间找到相关的搜索结果。",
                "icon": "https://cdn.gogoing.work/bookmark/www.baidu.com.png",
                "title": "百度一下",
                "unique": "f9751de431104b125f48dd79cc55822a",
                "localUnique": "a186e3048d9ab689b671d375086d3d74"
            },
            "widget": "bookmark"
        }],
        "unique": "884abd9bbb2007f86a0b3932e99325c2"
    }],
    "recommend": []
}
const emptyLayout = {
    recommend: [],
    fixed: [],
    groups: []
}

export const buildScene = (layout = null) => {
    if (!layout) {
        layout = emptyLayout
    } else if (layout === 'default') {
        layout = defaultLayout
    }
    return {
        "uuid": uuidv4(),
        "data": layout,
        'updated_at': moment().format('YYYY-MM-DD HH:mm:ss')
    }
}