import md5 from "spark-md5";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

var throttle_timer; // 维护一个 timer
//时间段内只执行第一次事件
export const throttle = (fn, delay) => {
    if (throttle_timer) {
        return;
    }
    throttle_timer = setTimeout(() => {
        fn.apply();
        throttle_timer = null; // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
    }, delay)
}

var debounce_timers = {}; // 维护一个 timer
//时间段内只执行最后一次事件
export const debounce = (fn, delay, timer = 'debounce') => {
    clearTimeout(debounce_timers[timer]);
    debounce_timers[timer] = setTimeout(() => {
        fn.apply(); // 用apply指向调用debounce的对象，相当于_this.fn(args);
    }, delay);
}

export const browserUniqueCode = async () => {
    const fpPromise = FingerprintJS.load()

    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    // console.log(result.visitorId)
    return result.visitorId

//     const outScreenCanvas = document.createElement('canvas')
//     const ctx = outScreenCanvas.getContext("2d");
// // Text with lowercase/uppercase/punctuation symbols
//     const txt = "BrowserLeaks.com <canvas> 1.0";
//     ctx.textBaseline = "top";
// // The most common type
//     ctx.font = "14px 'Arial'";
//     ctx.textBaseline = "alphabetic";
//     ctx.fillStyle = "#f60";
//     ctx.fillRect(125, 1, 62, 20);
// // Some tricks for color mixing to increase the difference in rendering
//     ctx.fillStyle = "#069";
//     ctx.fillText(txt, 2, 15);
//     ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
//     ctx.fillText(txt, 4, 17);
//     const bin = atob(outScreenCanvas.toDataURL().replace("data:image/png;base64,", ""))
//     console.log(bin2hex(bin.slice(-16, -12)))
//     return bin2hex(bin.slice(-16, -12))
}

// const bin2hex = (s) => {
//     let i, l, o = '', n;
//     s += '';
//     for (i = 0, l = s.length; i < l; i++) {
//         n = s.charCodeAt(i)
//             .toString(16);
//         o += n.length < 2 ? '0' + n : n;
//     }
//     return o;
// }

export const md5Random = () => {
    return md5.hash((new Date).toString() + Math.random())
}
