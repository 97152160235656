<template>
  <a-config-provider :locale="locale">
    <router-view v-if="init"/>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import "moment/dist/locale/zh-cn";
import {browserUniqueCode} from "@/tools/helper";
import Model from "@/models/Model";
import {mapState} from "vuex";
import {v4 as uuidv4} from 'uuid';
import {syncScene, syncLayout, syncPlugin, syncSetting, syncAll, syncCurrentScene} from "@/events/global";
import config from "@/config/app";
import Widget from "@/models/Widget";
import http from "@/events/http";
// import EchoMixin from "@/mixins/EchoMixin";

moment.locale('zh-cn');

export default {
  data() {
    return {
      locale: zhCN,
      init: false,
      echo: null,
    }
  },
  // mixins:[EchoMixin],
  created() {
    this.launch()
    // this.echoListen()
  },
  methods: {
    async launch() {
      this.hacks()
      const uuid = this.$ls.get('Uuid')
      this.$store.commit('setStorage', {IsNewUser: !uuid})
      //test区域
      // const model =new Model('group')
      // model.with('bookmarks').get().then(res=>{
      //   console.log(res)
      // })
      // const object = { 'a': { 'b': null } };
      // console.log(_.has(object, 'a.c'))

      // 注册全局事件
      this.registerGlobalEvent()
      // 加载客户端配置
      await this.clientInit()
      // 加载本地文件
      this.loadLocalStorage()
      // 检测版本信息,好像没有用先注释
      // this.checkVersion()
      this.browserEvent()

      // 同步登录,加载云端文件
      await Promise.all([
        this.loadWidgets(),
      ])

      this.init = true
    },
    async clientInit() {
      // 设置用户终端识别码
      const UniqueCode = await browserUniqueCode()
      this.$store.commit('setStorage', {UniqueCode})
      // 计算唯一码
      let Uuid = this.$ls.get('Uuid')
      if (!Uuid) {
        Uuid = uuidv4()
      }
      this.$store.commit('setStorage', {Uuid})
    },
    registerGlobalEvent() {
      const events = {
        'Sync': syncAll, //上传本地储存
        'updateLocalStorage': syncLayout, //上传本地储存
        'SyncSetting': syncSetting, //同步设置
        'SyncPlugin': syncPlugin, //同步设置
        'SyncScene': syncScene, //同步设置
        'SyncCurrentScene': syncCurrentScene, //同步设置
        'HTTP': http
      }
      for (let key in events) {
        this.$bus.off(key, events[key])
        this.$bus.on(key, events[key])
      }
    },
    loadLocalStorage() {
      const localToken = this.$ls.get('Token')
      if (localToken) {
        this.$store.commit('set', {Token: localToken})
      }
      const SyncedAt = this.$ls.get('SyncedAt') || this.$ls.get('LastUpdatedAt')//todo 2022-06-01删除后面的参数
      if (SyncedAt) {
        this.$store.commit('set', {SyncedAt: SyncedAt})
      }
      this.$store.commit('loadLocalUser')
      this.$store.commit('loadLocalSetting')
      this.$store.commit('loadLocalPlugin')
      this.$store.commit('loadLocalLayout')
    },
    checkVersion() {
      const LastCheckVersion = this.$ls.get('LastCheckVersion') || 0
      const now = (new Date()).getTime()
      if (now - LastCheckVersion > 300000) {
        // 最多5分钟检查一次
        const model = new Model('system')
        model.call('version').then(res => {
          // console.log(config.version, res.data, config.version !== res.data)
          if (config.version !== res.data) {
            this.$message.warning('正在更新...')
            this.$ls.set('LastCheckVersion', now)
            // window.location.reload()
          }
        })
      }
    },
    browserEvent() {
      this.windowResize()
      window.removeEventListener('resize', this.windowResize)
      window.addEventListener('resize', this.windowResize)
    },
    windowResize() {
      const IsMobile = window.innerWidth <= 750
      this.$store.commit('set', {IsMobile})
    },
    loadWidgets() {
      return new Promise(resolve => {
        let widgets = this.$ls.get('Widgets') || []
        let resolved = false
        if (widgets instanceof Array && widgets.length > 0) {
          this.$store.commit('set', {Widgets: widgets})
          resolve()
          resolved = true
        }
        const model = new Widget()
        model.get().then(res => {
          this.$store.commit('setStorage', {Widgets: res})
          if (!resolved) {
            resolve()
          }
        }).catch(() => {
          if (!resolved) {
            resolve()
          }
        })
      })
    },
    hacks() {
      document.getElementsByClassName('drag-grid').ondrop = function (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
  computed: {
    ...mapState(['Setting', 'User', 'Widgets', 'Token', 'SyncedAt']),
  },
  watch: {
    // Token() {
    //   debounce(() => {
    //     this.echoListen()
    //   }, 1000)
    // },
  }
}
</script>
<style lang="stylus">
html, body
  width 100%
  height 100%

*::-webkit-scrollbar {
  width: 8px;
  // height: 10px; // 高度写不写，都不影响，因为会根据内容的长度自动计算
}

::-webkit-scrollbar-track {
  background-color: rgba(245, 245, 245, 0);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.5); // 滑块颜色
  border-radius: 5px; // 滑块圆角
  opacity 0.5; // 透明度
}

// 兼容Firefox、IE
* {
  scrollbar-width: 5px;
  scrollbar-base-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
}


.no-mb .ant-tabs-top-bar {
  margin-bottom: 0;
}
</style>
