export default {
    type: "object",
    properties: {
        id: {type: "integer",},
        user_id: {type: "integer"},
        name: {type: "string", nullable: true},
        data: {$ref:"defs#/definitions/layout_group"},
        uuid: {type: "string"},
    },
    required: ["id", "uuid", 'data'],
}