import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import router from './service/router'
import store from './service/store'
import './service/http' //http服务
// import './service/meta' //头部信息
import './service/event' //事件总线
import './service/storage' //本地储存服务

import './service/lodash' //工具库
import './service/clipboard' //复制
// import './service/moment' //时间服务
import './service/context-menu' //右击服务

import './service/tailwind.css'
import './service/animate.css'
import './service/icon' //图标服务
import './service/iconfont' //图标服务
import './service/ant.ui'
import './service/tour' //引导服务

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
